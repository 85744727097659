<template>
    <section id="tour-details">
        <div class="grid-nogutter mt-2">
            <div class="col-12 xl:col-12 ">
                <template v-if="pageLoading">
                    <div class="card">
                        <div class="grid">
                            <div class="col-12 lg:col-8">
                                <Skeleton height="44rem"></Skeleton>
                            </div>
                            <div class="col-12 lg:col-4">
                                <Skeleton class="mb-3" height="5rem"></Skeleton>
                                <Skeleton class="mb-3" height="5rem"></Skeleton>
                                <Skeleton class="mb-3" height="5rem"></Skeleton>
                                <Skeleton class="mb-3" height="5rem"></Skeleton>
                                <Skeleton class="mb-3" height="5rem"></Skeleton>
                                <Skeleton class="mb-3" height="5rem"></Skeleton>
                                <Skeleton class="mb-3" height="5rem"></Skeleton>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="col-12 lg:col-6 xl:col-3">
                                <Skeleton class="mb-3" height="8rem"></Skeleton>
                            </div>
                            <div class="col-12 lg:col-6 xl:col-3">
                                <Skeleton class="mb-3" height="8rem"></Skeleton>
                            </div>
                            <div class="col-12 lg:col-6 xl:col-3">
                                <Skeleton class="mb-3" height="8rem"></Skeleton>
                            </div>
                            <div class="col-12 lg:col-6 xl:col-3">
                                <Skeleton class="mb-3" height="8rem"></Skeleton>
                            </div>
                        </div>
                        <div class="grid-nogutter">
                            <div class="col-12 mb-1">
                                <Skeleton height="3rem"></Skeleton>
                            </div>
                            <div class="col-12 mb-1">
                                <Skeleton height="3rem"></Skeleton>
                            </div>
                            <div class="col-12 mb-1">
                                <Skeleton height="3rem"></Skeleton>
                            </div>
                            <div class="col-12 mb-1">
                                <Skeleton height="3rem"></Skeleton>
                            </div>
                            <div class="col-12 mb-1">
                                <Skeleton height="3rem"></Skeleton>
                            </div>
                            <div class="col-12 mb-1">
                                <Skeleton height="3rem"></Skeleton>
                            </div>
                            <div class="col-12 mb-1">
                                <Skeleton height="3rem"></Skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="card">
                        <Toast />
                        <Dialog header="Confirmation" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true">
                            <div class="confirmation-content">
                                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                                <span>Are you sure you want to delete?</span>
                            </div>
                            <template #footer>
                                <Button label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text"/>
                                <Button label="Yes" icon="pi pi-check" @click="deleteTour" class="p-button-raised p-button-danger p-button-text" autofocus />
                            </template>
                        </Dialog>
                        <div class="grid mb-2">
                            <div class="col-12 lg:col-8">
                                <Galleria
                                    :value="images"
                                    :responsiveOptions="responsiveOptions"
                                    :numVisible="4"
                                    :showThumbnails="false"
                                    :showItemNavigators="true"
                                    :showIndicators="true"
                                    :autoPlay="true"
                                    :circular="true"
                                    :showIndicatorsOnItem="true"
                                >
                                    <template #item="slotProps">
                                        <img class="tour-image" :src="constant.imgUrl+slotProps.item.src" :alt="slotProps.item.alt" />
                                    </template>
                                    <template #thumbnail="slotProps">
                                        <img :src="constant.imgUrl+slotProps.item.src" :alt="slotProps.item.alt" style="width: 100%; display: block;" />
                                    </template>
                                </Galleria>
                            </div>
                            <div class="col-12 lg:col-4">
                                <div class="">
                                    <div class="mb-2">
                                        <div class="text-3xl text-primary font-semibold">{{ tour.title }}</div>
                                    </div>
                                    <div class="font-semibold mb-2">
                                        <i class="pi pi-map-marker"></i><span class="ml-2">{{ tour.destination.line + ', ' + tour.destination.district }}</span>
                                    </div>
                                    <div class="flex flex-wrap">
                                        <div class="flex-grow-1 flex align-items-center card card-container m-1 p-2">
                                            <div class="m-2">
                                                <div>Price Per Adult Person</div>
                                                <div class="flex align-items-end flex-wrap font-semibold text-xl">
                                                    <template v-if="tour.discounted_price_adults == 0">
                                                        <span class="flex align-items-end justify-content-center text-primary mr-1">BDT</span>
                                                        <span class="flex align-items-end justify-content-center text-primary mx-1">{{ tour.price_adults.toLocaleString('en-BD') }}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span class="flex align-items-end justify-content-center text-base old-price">
                                                            <span>BDT</span>
                                                            <span>{{ tour.price_adults.toLocaleString('en-BD') }}</span>
                                                        </span>
                                                        <span class="flex align-items-end justify-content-center text-primary mx-2">
                                                            <span class="mr-1">BDT</span>
                                                            <span class="">{{ tour.discounted_price_adults.toLocaleString('en-BD') }}</span>
                                                        </span>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-wrap">
                                        <div class="flex-grow-1 flex align-items-center card m-1 p-2">
                                            <div class="m-2">
                                                <div>Price Per Child</div>
                                                <div class="flex align-items-end flex-wrap font-semibold text-xl">
                                                    <template v-if="tour.discounted_price_children == 0">
                                                        <span class="flex align-items-end justify-content-center text-primary mr-1">BDT</span>
                                                        <span class="flex align-items-end justify-content-center text-primary mx-1">{{ tour.price_children.toLocaleString('en-BD') }}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span class="flex align-items-end justify-content-center text-base old-price">
                                                            <span>BDT</span>
                                                            <span>{{ tour.price_children.toLocaleString('en-BD') }}</span>
                                                        </span>
                                                        <span class="flex align-items-end justify-content-center text-primary mx-2">
                                                            <span class="mr-1">BDT</span>
                                                            <span class="">{{ tour.discounted_price_children.toLocaleString('en-BD') }}</span>
                                                        </span>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-column mt-4">
                                        <div class="flex mx-1">
                                            <Button class="flex-1 flex p-button-raised p-button-warning p-button-lg w-full" label="Place A Booking" />
                                        </div>
                                        <div class="flex mx-1 mt-3">
                                            <Button icon="pi pi-pencil" class="flex-1 flex p-button-raised p-button-success p-button-lg w-full" label="Update" @click="updateTour" />
                                        </div>
                                        <div class="flex mt-2">
                                            <div class="flex flex-1 mx-1">
                                                <Button label="Back to list" icon="pi pi-arrow-left" class="p-button-raised p-button w-full mt-2" @click="backToList"></Button>
                                            </div>
                                            <div class="flex flex-1 mx-1">
                                                <Button icon="pi pi-trash" class="p-button-raised p-button-danger w-full mt-2" label="Delete" @click="openConfirmation" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="flex-grow-1 flex align-items-center card bg-blue-100 m-1 p-2">
                                <div>
                                    <div class="m-2">
                                        <i class="pi pi-arrow-right"></i>
                                        <span class="ml-2">{{ formatDate(tour.start_date) }}</span>
                                    </div>
                                    <div class="m-2">
                                        <i class="pi pi-arrow-left"></i>
                                        <span class="ml-2">{{ formatDate(tour.end_date) }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1 flex align-items-center card bg-green-100 m-1 p-2">
                                <div>
                                    <div class="m-2">
                                        <i class="pi pi-sun"></i>
                                        <span class="ml-2">{{ tour.day + ' days' }}</span>
                                    </div>
                                    <div class="m-2">
                                        <i class="pi pi-moon"></i>
                                        <span class="ml-2">{{ tour.night + ' nights' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1 flex align-items-center card bg-yellow-100 m-1 p-2">
                                <div>
                                    <div class="m-2">
                                        <i class="pi pi-user-plus"></i>
                                        <span class="ml-2">{{ (tour.seat_total - tour.seat_booked) + ' seats available' }}</span>
                                    </div>
                                    <div class="m-2">
                                        <i class="pi pi-users"></i>
                                        <span class="ml-2">{{ tour.seat_total + ' seats total' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1 flex align-items-center card bg-cyan-100 m-1 p-2">
                                <div class="m-2">
                                    <div>Total meals</div>
                                    <div class="font-semibold">{{ tour.meals + ' times' }}</div>
                                </div>
                            </div>
                            <div class="flex-grow-1 flex align-items-center card bg-pink-100 m-1 p-2">
                                <div class="m-2">
                                    <div>Booking deadline</div>
                                    <div class="font-semibold text-orange-700">{{ formatDate(tour.booking_deadline) }}</div>
                                </div>
                            </div>
                            <div class="flex-grow-1 flex align-items-center card bg-indigo-100 m-1 p-2">
                                <div class="m-2">
                                    <div>Cancellation period</div>
                                    <div class="font-semibold">{{ formatDate(tour.cancellation_period) }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="flex-grow-1 flex align-items-center card bg-teal-100 m-1 p-2">
                                <div class="m-2">
                                    <div>Total category</div>
                                    <div class="font-semibold">{{ tour.category.name }}</div>
                                </div>
                            </div>
                            <div class="flex-grow-1 flex align-items-center card bg-orange-100 m-1 p-2">
                                <div class="m-2">
                                    <div>Total type</div>
                                    <div class="font-semibold">{{ tour.type.name }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mx-1 mt-2">
                            <div class="grid">
                                <div class="col-12 lg:col-6 xl:col-6">
                                    <div class="card surface-200 mb-0">
                                        <div class="flex justify-content-between mb-2">
                                            <div>
                                                <span class="block text-500 font-medium mb-2">Starting Point</span>
                                                <div class="text-900 font-semibold text-xl">{{ tour.starting_point.line }}</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                                                <i class="pi pi-map-marker text-blue-500 text-xl"></i>
                                            </div>
                                        </div>
                                        <span class="text-500">{{tour.starting_point.thana + ', ' + tour.starting_point.district + ', ' + tour.starting_point.division }}</span>
                                    </div>
                                </div>
                                <div class="col-12 lg:col-6 xl:col-6">
                                    <div class="card surface-200 mb-0">
                                        <div class="flex justify-content-between mb-2">
                                            <div>
                                                <span class="block text-500 font-medium mb-2">Destination</span>
                                                <div class="text-900 font-semibold text-xl">{{ tour.destination.line }}</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
                                                <i class="pi pi-map-marker text-orange-500 text-xl"></i>
                                            </div>
                                        </div>
                                        <span class="text-500">{{ tour.destination.thana + ', ' + tour.destination.district + ', ' + tour.destination.division }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mx-1 mt-2">
                            <div class="grid p-fluid">
                                <div class="col-12">
                                    <Accordion class="accordion-custom" v-model:activeIndex="active">
                                        <AccordionTab header="Description">
                                            <div class="grid">
                                                <div class="col"></div>
                                            </div>
                                            <p v-html=" tour.description"></p>
                                        </AccordionTab>
                                        <AccordionTab header="Schedule">
                                            <Timeline :value="tour.schedule" align="alternate" class="customized-timeline">
                                                <template #marker="slotProps">
                                                    <span class="custom-marker border-round text-white font-bold bg-teal-500 shadow-3">DAY {{ slotProps.item.day }}</span>
                                                </template>
                                                <template #content="slotProps">
                                                    <div v-for="(activity, index) in slotProps.item.activities" :key="index">
                                                        <div class="card mb-2">
                                                            <div class="text-xl font-semibold mb-1">{{ activity.title }}</div>
                                                            <div class="text-sm text-green-500 mb-2">{{ activity.time }}</div>
                                                            <div>{{ activity.description }}</div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </Timeline>
                                        </AccordionTab>
                                        <AccordionTab header="Places">
                                            <p v-for="item in tour.places" :key="item.index">
                                                <i class="pi pi-flag"></i>
                                                {{ item }}
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="Prerequisites">
                                            <p v-for="item in tour.prerequisites" :key="item.index">
                                                <i class="pi pi-exclamation-triangle"></i>
                                                {{ item }}
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="Included">
                                            <p v-for="item in tour.included" :key="item.index">
                                                <i class="pi pi-check-circle" style="color: #21c392"></i>
                                                {{ item }}
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="Excluded">
                                            <p v-for="item in tour.excluded" :key="item.index">
                                                <i class="pi pi-times-circle" style="color: #f3954f"></i>
                                                {{ item }}
                                            </p>
                                        </AccordionTab>
                                        <AccordionTab header="Tour Policy">
                                            <p v-html="tour.tour_policy" v-if="tour.tour_policy"></p>
                                            <p v-else>---</p>
                                        </AccordionTab>
                                        <AccordionTab header="Cancellation Policy">
                                            <p v-html="tour.cancellation_policy" v-if="tour.cancellation_policy"></p>
                                            <p v-else>---</p>
                                        </AccordionTab>
                                        <AccordionTab header="Children Policy">
                                            <p v-html="tour.children_policy" v-if="tour.children_policy"></p>
                                            <p v-else>---</p>
                                        </AccordionTab>
                                    </Accordion>
                                </div>
                                <div class="col-12 p-md-4"></div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Axios from 'axios';
import moment from 'moment';
import Constant from '../../data/constant'

export default {
    setup() {
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();

        const constant =  ref(new Constant());

        const slug = ref();
        slug.value = route.params.slug;

        const id = ref();
        id.value = route.params.id;

        const displayConfirmation = ref(false);

        const pageLoading = ref(true);
        const tour = ref([]);
        const images = ref([]);
        const active = ref(0);

        const responsiveOptions = ref([
            {
                breakpoint: '1440x',
                numVisible: 4
            },
            {
                breakpoint: '1200px',
                numVisible: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2
            }
        ]);

        const getTourDetails = () => {
            Axios
                .get("api/admin/tour/view/"+slug.value)
                .then((res) =>{
                    if (res.data.response == "success") {
                        tour.value = res.data.data;
                        console.log(tour.value);
                        if(tour.value.first_image != null){
                            images.value.push({
                                src: tour.value.first_image,
                                alt: "First image"
                            });
                        }
                        if(tour.value.second_image != null){
                            images.value.push({
                                src: tour.value.second_image,
                                alt: "Second image"
                            });
                        }
                        if(tour.value.third_image != null){
                            images.value.push({
                                src: tour.value.third_image,
                                alt: "Third image"
                            });
                        }
                        if(tour.value.fourth_image != null){
                            images.value.push({
                                src: tour.value.fourth_image,
                                alt: "Fourth image"
                            });
                        }
                    }
                    else{
                        showToast("error", "Error", res.data.message);
                    }
                    pageLoading.value = false;
                })
                .catch((err) =>{
                    console.log(err);
                    pageLoading.value = false;
                });
        };
        getTourDetails();

        const backToList = () => {
            router.replace({ name: 'tours' });
        };

        const updateTour = () => {
            router.push({name:'u-basic', params:{id: id.value, slug: slug.value}});
        };

        const openConfirmation = () => {
            displayConfirmation.value = true;
        };
        
        const closeConfirmation = () => {
            displayConfirmation.value = false;
        };
        const deleteTour = () => {
            Axios
            .delete("api/admin/tour/delete/"+id.value)
            .then(res => {
                if(res.data.response == "success"){
                location.reload();
                }
                else{
                showToast("error", "Error", res.data.message);
                }
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
            displayConfirmation.value = false;
        };

        const formatDate = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY");
        };

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        }

        return {
            pageLoading,
            tour,
            images,
            active,
            constant,

            responsiveOptions,

            getTourDetails,
            backToList,
            updateTour,
            openConfirmation,
            closeConfirmation,
            displayConfirmation,
            deleteTour,

            formatDate,
            showToast,
        };
    },
};
</script>

<style lang="scss" scoped>
.custom-marker {
    display: flex;
    width: 5rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
}
::v-deep(.p-timeline-event-content) ::v-deep(.p-timeline-event-opposite) {
    line-height: 1;
}

@media screen and (max-width: 960px) {
    ::v-deep(.customized-timeline) {
        .p-timeline-event:nth-child(even) {
            flex-direction: row !important;

            .p-timeline-event-content {
                text-align: left !important;
            }
        }

        .p-timeline-event-opposite {
            flex: 0;
        }

        .p-card {
            margin-top: 1rem;
        }
    }
    .tour-image {
        height: auto;
    }
}

.tour-image {
    width: 100%;
    object-fit: cover;
}
@media screen and (max-width: 480px) {
    .tour-image {
        height: 180px;
    }
}
@media only screen and (min-width: 576px) {
    .tour-image {
        height: 240px;
    }
}

@media only screen and (min-width: 768px) {
    .tour-image {
        height: 320px;
    }
}

@media only screen and (min-width: 992px) {
    .tour-image {
        height: 400px;
    }
}

@media only screen and (min-width: 1200px) {
    .tour-image {
        height: 420px;
    }
}
.card-title {
    color: #0a5688;
    margin: 0 !important;
}
.old-price {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
}
</style>